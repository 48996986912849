<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <h2>Profile</h2>
      </v-card-title>
    </v-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({})
};
</script>
<style lang=""></style>
